import * as React from "react";
import PropTypes from "prop-types";
import { GridOverlay, DataGrid } from "@material-ui/data-grid";
import { cardCondition } from "../../utils/card-condition";
import { FlagIcon } from "../../components/FlagIcon";
import { useListItems } from "./hooks";
import { UserWidget } from "../users/UserWidget";
import { ProposeTradeButton } from "../trades/ProposeTradeButton";
import { AddCardVariantButton } from "./buttons/AddCardVariantButton";

const actionButtons = (params) => {
    return (
        <>
            <ProposeTradeButton userId={params.row.created_by.id} />
        </>
    );
};
const CustomNoRowsOverlay = () => {
    return (
        <GridOverlay>
            <p>This card not currently offered for trade</p>
        </GridOverlay>
    );
};

export const TradeListItemsTable = ({ cardId, isVirtualCard }) => {
    const { isLoading, data: rows } = useListItems({
        listNames: [`OfferForTrade`],
        cardId,
    });
    const columns = [
        {
            field: `trader`,
            headerName: `Trader`,
            description: `Trader`,
            width: 160,
            renderCell: (params) => (
                <UserWidget userId={params.row.created_by.id} variant="small" />
            ),
            sortable: false,
        },
        {
            field: `conditionText`,
            headerName: `Condition`,
            description: `Condition`,
            width: 130,
            valueGetter: (params) =>
                cardCondition[
                    params.row.condition ? params.row.condition.id : 0
                ],
            sortable: false,
        },
        {
            field: `style`,
            headerName: `Style`,
            description: `Style`,
            width: 120,
            valueGetter: (params) =>
                params.row.foiled
                    ? `Foiled`
                    : params.row.reverse_foiled
                    ? `Reverse foiled`
                    : `Standard`,
            sortable: false,
        },
        {
            field: `language`,
            headerName: `Language`,
            description: `Language`,
            width: 130,
            renderCell: (params) => (
                <FlagIcon
                    className="mr-2"
                    code={params.row.language}
                    key={params.row.language}
                />
            ),
            sortable: false,
        },
        {
            field: `signed`,
            headerName: `Signed`,
            description: `Signed`,
            width: 105,
            valueGetter: (params) => (params.row.signed ? `Y` : `N`),
            sortable: false,
        },
        {
            field: `quantity`,
            headerName: `Qty`,
            description: `Quantity`,
            width: 80,
            sortable: false,
        },
        {
            field: `Actions`,
            headerName: `Actions`,
            description: `Actions`,
            width: 120,
            renderCell: (params) => actionButtons(params),
            sortable: false,
        },
    ];

    return (
        <div className={`relative`} style={{ height: `25em` }}>
            <DataGrid
                columnBuffer={2}
                columns={columns}
                components={{
                    NoRowsOverlay: CustomNoRowsOverlay,
                }}
                disableColumnMenu
                disableSelectionOnClick
                headerHeight={52}
                hideFooterPagination
                hideFooterRowCount
                hideFooterSelectedRowCount
                loading={isLoading}
                rowHeight={60}
                rows={isVirtualCard ? [] : rows}
                scrollbarSize={15}
            />
            <AddCardVariantButton cardId={cardId} listName="OfferForTrade" />
        </div>
    );
};

TradeListItemsTable.propTypes = {
    cardId: PropTypes.number,
    isVirtualCard: PropTypes.bool,
};
