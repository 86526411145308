import * as React from "react";
import PropTypes from "prop-types";
import { Button, IconButton, Tooltip } from "@material-ui/core";

export const PrimaryActionButton = ({
    buttonClasses,
    children,
    className = ``,
    disabled = false,
    handleClick,
    icon,
    muiButtonParams = {},
    tooltip,
    variant = `icon`,
}) => {
    const color = `primary`;
    const wrapperClasses = `${className}`;

    const Btn = () => {
        switch (variant) {
            case `text`:
                return (
                    <Button
                        aria-label={tooltip}
                        className={buttonClasses}
                        color={color}
                        disabled={disabled}
                        onClick={handleClick}
                        {...muiButtonParams}
                    >
                        {children}
                    </Button>
                );
            case `iconText`:
                return (
                    <Button
                        aria-label={tooltip}
                        className={buttonClasses}
                        color={color}
                        disabled={disabled}
                        onClick={handleClick}
                        startIcon={icon}
                        variant="contained"
                        {...muiButtonParams}
                    >
                        {children}
                    </Button>
                );
            default:
                return (
                    <IconButton
                        aria-label={tooltip}
                        className={buttonClasses}
                        color={color}
                        disabled={disabled}
                        onClick={handleClick}
                        {...muiButtonParams}
                    >
                        {icon}
                    </IconButton>
                );
        }
    };

    return (
        <>
            <Tooltip title={tooltip}>
                <span className={wrapperClasses}>
                    <Btn />
                </span>
            </Tooltip>
        </>
    );
};

PrimaryActionButton.propTypes = {
    buttonClasses: PropTypes.string,
    children: PropTypes.object,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    icon: PropTypes.object,
    muiButtonParams: PropTypes.object,
    tooltip: PropTypes.string.isRequired,
    variant: PropTypes.oneOf([`icon`, `iconText`, `text`]),
};
