import * as React from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { IconButton, Tooltip } from "@material-ui/core";
import DeleteIcon from "@mui/icons-material/Delete";
import { useMutation, useQueryClient } from "react-query";
import { v1 as uuidv1 } from "uuid";
import { removeItem } from "../listsClient";
import { addMessage } from "../../alerts/messagesSlice";

export const DeleteButton = ({
    listItemId,
    disabled = false,
    listName,
    tooltip = `Delete`,
}) => {
    const color = `primary`;
    const dispatch = useDispatch();
    const queryClient = useQueryClient();
    const { mutateAsync, isLoading } = useMutation(removeItem, {
        onError: (err) => {
            dispatch(
                addMessage({
                    id: uuidv1(),
                    text: `Error removing card from list: ${err}`,
                    priority: `low`,
                })
            );
        },
        onSuccess: (data, variables) => {
            dispatch(
                addMessage({
                    id: uuidv1(),
                    text: `Card removed from list`,
                    priority: `low`,
                })
            );
        },
    });
    const handleDelete = async () => {
        await mutateAsync({ listName, listItemId });
        // TODO: Invalidate only specific list for this user
        await queryClient.invalidateQueries(`list`);
    };

    return (
        <>
            <Tooltip title={tooltip}>
                <span>
                    <IconButton
                        aria-label={tooltip}
                        color={color}
                        disabled={isLoading || disabled}
                        onClick={handleDelete}
                    >
                        <DeleteIcon />
                    </IconButton>
                </span>
            </Tooltip>
        </>
    );
};

DeleteButton.propTypes = {
    listItemId: PropTypes.number.isRequired,
    disabled: PropTypes.bool,
    listName: PropTypes.string.isRequired,
    tooltip: PropTypes.string,
};
