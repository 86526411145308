import * as React from "react";
import { Grid, Paper, Tab, Typography, useMediaQuery } from "@material-ui/core";
import { Skeleton, TabContext, TabList, TabPanel } from "@material-ui/lab";
import { navigate } from "gatsby-link";

import { useUIDispatch } from "../app/uiContext";
import { useCard } from "../features/cards/hooks";
import { useAuth } from "../context/authContext";
import { useIsMounted } from "../common/hooks";
import { Layout } from "../common/Layout";
import { SEO } from "../components/seo";
import { Heading } from "../components/heading";
import { CardDetails } from "../features/cards/CardDetails";
import { LoginLink } from "../features/account/LoginLink";
import { MyListItemsTable } from "../features/lists/MyListItemsTable";
import { SaleListItemsTable } from "../features/lists/SaleListItemsTable";
import { TradeListItemsTable } from "../features/lists/TradeListItemsTable";
import NotFoundPage from "./404";

const tabPositionProps = (isLarge) => {
    return !isLarge
        ? {
              variant: `fullWidth`,
          }
        : {
              centered: true,
          };
};
const ListTabs = ({ cardId, isLoggedIn, isVirtualCard }) => {
    const uiDispatch = useUIDispatch();
    const [tabName, setTabName] = React.useState(`Sale`);
    const handleChange = (event, newValue) => {
        if (!isLoggedIn && newValue === `Mine`) {
            uiDispatch({ type: `SHOW_LOGIN` });
        }
        setTabName(newValue);
    };

    return (
        <TabContext value={tabName}>
            <TabList
                aria-label="cards"
                onChange={handleChange}
                value={tabName}
                {...tabPositionProps(useMediaQuery(`(min-width:67rem)`))}
            >
                <Tab label="For Sale" value="Sale" />
                <Tab label="For Trade" value="Trade" />
                <Tab label="Mine" value="Mine" />
            </TabList>
            <TabPanel value="Sale">
                <SaleListItemsTable
                    cardId={cardId}
                    isVirtualCard={isVirtualCard}
                />
            </TabPanel>
            <TabPanel value="Trade">
                <TradeListItemsTable
                    cardId={cardId}
                    isVirtualCard={isVirtualCard}
                />
            </TabPanel>
            <TabPanel value="Mine">
                {isLoggedIn && (
                    <MyListItemsTable
                        cardId={cardId}
                        isVirtualCard={isVirtualCard}
                    />
                )}
            </TabPanel>
        </TabContext>
    );
};

const CardPage = ({ ...props }) => {
    const isMounted = useIsMounted();
    const cardId = Number(props[`*`].split(`/`).shift());
    isMounted && !cardId && navigate(`/games/`, { replace: true });
    const currUrl = `/card/${props[`*`]}`;
    const { isLoggedIn } = useAuth();
    const {
        isError,
        isFetching,
        isLoading,
        data: result,
        error,
    } = useCard(cardId);

    if (isError && error.code === 203) {
        return <NotFoundPage />;
    }

    let canonicalUrl;
    if (isMounted && !isFetching && !isLoading) {
        // Make sure we only use Canonical URLs
        canonicalUrl = `/card/${cardId}/${result.data.url_title}`;
        if (canonicalUrl !== currUrl) {
            // TODO: 301 for SEO
            navigate(canonicalUrl, { replace: true });
        }
    }
    const pageTitle =
        result && result.data.name
            ? `${result.data.name} - ${result.data.set.series.game.name}`
            : `Loading...`;
    const isVirtualCard =
        result && result.data.rarity && result.data.rarity.id === 3;

    return isMounted ? (
        <Layout>
            <SEO
                canonicalUrl={canonicalUrl}
                keywords={[`ccg`, `collectible`, `card`, `game`, `trade`]}
                title={pageTitle}
            />
            <section className="">
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        {isLoading ? (
                            <Skeleton
                                animation="pulse"
                                height={48}
                                variant="text"
                            />
                        ) : (
                            <Heading
                                text={
                                    result.data
                                        ? result.data.name
                                        : `Loading...`
                                }
                            />
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        <CardDetails card={result ? result.data : null} error={error} isError={isError} isLoading={isLoading} />
                    </Grid>
                    <Grid item xs={12}>
                        {!isLoggedIn() && (
                            <Paper className="mb-4 p-4">
                                <Typography>
                                    <LoginLink className="focus:text-primary hover:text-primary-light text-primary">
                                        Login/Register
                                    </LoginLink>{` `}
                                    to trade this card or add it to your
                                    collection
                                </Typography>
                            </Paper>
                        )}
                        <ListTabs
                            cardId={cardId}
                            isLoggedIn={isLoggedIn()}
                            isVirtualCard={isVirtualCard}
                        />
                    </Grid>
                </Grid>
            </section>
        </Layout>
    ) : null;
};

export default CardPage;
