import * as React from "react";
import PropTypes from "prop-types";
import { MenuItem, TextField } from "@material-ui/core";

export const ListSelector = ({
    handleListChange,
    isVirtualCard = true,
    margin = `normal`,
    selectedOption,
    ...props
}) => {
    return (
        <TextField
            disabled={isVirtualCard}
            fullWidth
            id="select-list"
            label="List"
            margin={margin}
            onChange={handleListChange}
            select
            value={selectedOption || `MyCollection`}
            variant="outlined"
            {...props}
        >
            <MenuItem className="text-base px-4 py-2" value="MyCollection">
                My collection
            </MenuItem>
            <MenuItem
                className="text-base px-4 py-2"
                disabled={isVirtualCard}
                value="WantInTrade"
            >
                Trades: Wanted
            </MenuItem>
            <MenuItem
                className="text-base px-4 py-2"
                disabled={isVirtualCard}
                value="OfferForTrade"
            >
                Trades: Offered
            </MenuItem>
            <MenuItem
                className="text-base px-4 py-2"
                disabled={isVirtualCard}
                value="Buying"
            >
                Sales: Wanted
            </MenuItem>
            <MenuItem
                className="text-base px-4 py-2"
                disabled={true}
                value="Selling"
            >
                Sales: Offered
            </MenuItem>
        </TextField>
    );
};

ListSelector.propTypes = {
    handleListChange: PropTypes.func.isRequired,
    isVirtualCard: PropTypes.bool,
    selectedOption: PropTypes.string.isRequired,
};
