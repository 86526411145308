// Dialog to manage a single card on a list.
import * as React from "react";
import PropTypes from "prop-types";
import { useMutation, useQueryClient } from "react-query";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormLabel,
    Switch,
} from "@material-ui/core";
import { v1 as uuidv1 } from "uuid";
import { useDispatch } from "react-redux";
import { LanguageSelector } from "../../common/LanguageSelector";
import { CardStyleSelector } from "../cards/CardStyleSelector";
import { CardConditionSelector } from "../cards/CardConditionSelector";
import { CardQuantitySelector } from "../cards/CardQuantitySelector";
import { addItem, updateItem } from "./listsClient";
import { addMessage } from "../alerts/messagesSlice";
import { useUserPreferences } from "../account/hooks";
import { ListSelector } from "./ListSelector";
import { useUser } from "../users/hooks";

export const ListItemDialog = ({
    availableLanguages,
    card,
    fullScreen,
    handleClose,
    heading,
    listName,
    open,
}) => {
    const availableStyles = React.useMemo(() => {
        let available = [];
        card.availableStandard && available.push(`standard`);
        card.availableFoiled && available.push(`foiled`);
        card.availableReverseFoiled && available.push(`reverse-foiled`);
        return available;
    }, [card]);
    const dispatch = useDispatch();
    const user = useUser();
    const userId = user && user.data && Number(user.data.data.id);
    const { data: prefs } = useUserPreferences(userId);
    const itemId = card.itemId || 0;
    const queryClient = useQueryClient();
    const [lName, setLName] = React.useState(listName || `MyCollection`);
    const [quantity, setQuantity] = React.useState(card.quantity || 1);
    const [condition, setCondition] = React.useState(card.condition || 3);
    const initialLanguage =
        card.language && availableLanguages.includes(card.language)
            ? card.language
            : availableLanguages[0];
    const [language, setLanguage] = React.useState(initialLanguage);
    const initialStyle = card.foiled
        ? `foiled`
        : card.reverse_foiled
        ? `reverse-foiled`
        : `standard`;
    const [style, setStyle] = React.useState(initialStyle);
    const [signed, setSigned] = React.useState(card.signed || false);
    const { mutateAsync: mutateAsyncAdd } = useMutation(addItem, {
        onError: (err) => {
            dispatch(
                addMessage({
                    id: uuidv1(),
                    text: `Failed adding card to list: ${err}`,
                    priority: `low`,
                })
            );
        },
        onSuccess: (data, variables) => {
            dispatch(
                addMessage({
                    id: uuidv1(),
                    text: `Card added to list`,
                    priority: `low`,
                })
            );
        },
    });
    const { mutateAsync: mutateAsyncUpdate } = useMutation(updateItem, {
        onError: (err) => {
            dispatch(
                addMessage({
                    id: uuidv1(),
                    text: `Failed updating card: ${err}`,
                    priority: `low`,
                })
            );
        },
        onSuccess: (data, variables) => {
            dispatch(
                addMessage({
                    id: uuidv1(),
                    text: `Card updated`,
                    priority: `low`,
                })
            );
        },
    });
    const handleListChange = (event) => {
        setLName(event.target.value);
    };
    const handleQuantityChange = (newQuantity) => {
        setQuantity(newQuantity);
    };
    const handleConditionChange = (event) => {
        setCondition(Number(event.target.value));
    };
    const handleStyleChange = (event) => {
        setStyle(event.target.value);
    };
    const handleSave = async () => {
        if (!itemId && quantity > 0) {
            await mutateAsyncAdd({
                listName: lName,
                cardId: card.cardId,
                quantity,
                condition,
                language,
                foiled: style === `foiled`,
                reverseFoiled: style === `reverse-foiled`,
                signed,
            });
            await queryClient.invalidateQueries(`list`);
        } else if (itemId && quantity > 0) {
            await mutateAsyncUpdate({
                listName: lName,
                itemId: itemId,
                quantity,
                condition,
                language,
                foiled: style === `foiled`,
                reverseFoiled: style === `reverse-foiled`,
                signed,
            });
            await queryClient.invalidateQueries(`list`);
        } else {
            dispatch(
                addMessage({
                    id: uuidv1(),
                    text: `Failed adding card to list`,
                    priority: `low`,
                })
            );
        }
        handleClose();
    };
    React.useEffect(() => {
        if (!itemId) {
            const defaultStyle =
                prefs && availableStyles.includes(prefs.default_card_style)
                    ? prefs.default_card_style
                    : availableStyles[0];
            setStyle(defaultStyle);
            if (
                prefs &&
                availableLanguages.includes(prefs.default_card_language)
            ) {
                setLanguage(prefs.default_card_language);
            }
            if (prefs && prefs.default_condition) {
                setCondition(prefs.default_condition);
            }
            if (prefs && prefs.default_quantity) {
                setQuantity(prefs.default_quantity);
            }
        }
    }, [availableLanguages, availableStyles, itemId, prefs]);

    return (
        <Dialog
            aria-labelledby="dialog-title"
            fullScreen={fullScreen}
            fullWidth={false}
            maxWidth="sm"
            onClose={handleClose}
            open={open}
            scroll="body"
        >
            <DialogTitle id="dialog-title">{heading}</DialogTitle>
            <DialogContent>
                <ListSelector
                    disabled={itemId > 0}
                    handleListChange={handleListChange}
                    isVirtualCard={card && card.isVirtualCard}
                    selectedOption={lName}
                />
                <CardConditionSelector
                    fullWidth
                    handleConditionChange={handleConditionChange}
                    listName={lName}
                    selectedOption={condition}
                />
                <LanguageSelector
                    availableLanguages={availableLanguages}
                    fullWidth
                    selected={language}
                    setSelected={setLanguage}
                />
                <CardStyleSelector
                    availableStyles={availableStyles}
                    fullWidth
                    handleStyleChange={handleStyleChange}
                    selectedOption={style}
                />
                <CardQuantitySelector
                    handleQuantityChange={handleQuantityChange}
                    quantity={quantity}
                />
                <FormControl fullWidth margin="normal">
                    <FormLabel id="list-label-signed">Signed</FormLabel>
                    <Switch
                        checked={signed}
                        disabled={!card.availableSigned}
                        edge="end"
                        inputProps={{ "aria-labelledby": `list-label-signed` }}
                        onChange={() => {
                            setSigned(!signed);
                        }}
                    />
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button
                    color="primary"
                    onClick={handleSave}
                    variant="contained"
                >
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};

ListItemDialog.propTypes = {
    card: PropTypes.object.isRequired,
};
