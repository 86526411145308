import * as React from "react";
import PropTypes from "prop-types";
import { navigate } from "gatsby";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import { PrimaryActionButton } from "../../common/PrimaryActionButton";

export const ProposeTradeButton = ({
    buttonClasses,
    children,
    className,
    disabled = false,
    muiButtonParams,
    tooltip = `Propose trade`,
    userId,
    variant = `icon`,
}) => {
    const handleProposeTrade = () => {
        // Can't find a way to use a Gatsby Link component
        navigate(`/dashboard/trade/new/` + userId + `/`);
    };

    return (
        <PrimaryActionButton
            buttonClasses={buttonClasses}
            className={className}
            disabled={disabled}
            handleClick={handleProposeTrade}
            icon={<SwapHorizIcon />}
            muiButtonParams={muiButtonParams}
            tooltip={tooltip}
            variant={variant}
        >
            {children}
        </PrimaryActionButton>
    );
};

ProposeTradeButton.propTypes = {
    buttonClasses: PropTypes.string,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    muiButtonParams: PropTypes.object,
    tooltip: PropTypes.string,
    variant: PropTypes.oneOf([`icon`, `iconText`, `text`]),
};
