import * as React from "react";
import { IconButton, Tooltip } from "@material-ui/core";
import { ListItemDialog } from "../ListItemDialog";

export const ListButton = ({
    availableLanguages,
    card,
    color = `primary`,
    disabled = false,
    heading,
    icon,
    listName,
    tooltip,
}) => {
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const handleShowDialog = () => {
        setDialogOpen(true);
    };
    const handleClose = () => {
        setDialogOpen(false);
    };

    return (
        <>
            <Tooltip title={tooltip}>
                <span>
                    <IconButton
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        aria-label={tooltip}
                        color={color}
                        disabled={disabled}
                        onClick={handleShowDialog}
                    >
                        {icon}
                    </IconButton>
                </span>
            </Tooltip>
            {card && !disabled && (
                <ListItemDialog
                    availableLanguages={availableLanguages}
                    card={card}
                    handleClose={handleClose}
                    heading={heading}
                    listName={listName}
                    open={dialogOpen}
                />
            )}
        </>
    );
};
