import * as React from "react";
import { useUIDispatch } from "../../app/uiContext";

export const LoginLink = ({ children, redirect, ...props }) => {
    const uiDispatch = useUIDispatch();
    const handleClick = (event) => {
        event.preventDefault();
        uiDispatch({ type: `SHOW_LOGIN`, payload: redirect });
    };

    return (
        <button onClick={handleClick} onKeyPress={handleClick} {...props}>
            {children}
        </button>
    );
};
