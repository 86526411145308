import * as React from "react";
import PropTypes from "prop-types";
import { ListButton } from "./ListButton";
import EditIcon from "@mui/icons-material/Edit";

export const EditButton = ({ item, listName, tooltip = `Edit` }) => {
    const languages =
        item.card.languages_override && item.card.languages_override.length > 0
            ? item.card.languages_override
            : item.card.set.series.game.languages;
    const card = {
        ...item,
        ...item.card,
        cardId: item.card.id,
        itemId: item.id,
        condition: item.condition ? item.condition.id : 0,
    };

    return (
        <ListButton
            availableLanguages={languages}
            card={card}
            heading={tooltip}
            icon={<EditIcon />}
            listName={listName}
            tooltip={tooltip}
        />
    );
};

EditButton.propTypes = {
    item: PropTypes.object.isRequired,
    listName: PropTypes.string.isRequired,
    tooltip: PropTypes.string,
};
